<template>
  <div class="sources-n-partners-block">
    <span class="title">
      <strong>
        Sources & Partenaires
      </strong>
    </span>
    <div class="s-n-p-container">
      <div class="s-n-p-item" tabindex="0">
        <nuxt-img
          loading="lazy"
          format="webp"
          width="126"
          height="22"
          src="/images/bodacc.png"
          alt="BODACC"
        />
      </div>
      <div class="s-n-p-item" tabindex="0">
        <nuxt-img
          loading="lazy"
          format="webp"
          src="/images/nota-pme.png"
          width="136"
          height="43"
          alt="Nota PME"
        />
      </div>
      <div class="s-n-p-item" tabindex="0">
        <nuxt-img
          loading="lazy"
          format="webp"
          width="87"
          height="49"
          src="/images/inpi.png"
          alt="INPI"
        />
      </div>
      <div class="s-n-p-item" tabindex="0">
        <nuxt-img
          loading="lazy"
          format="webp"
          src="/images/insee.png"
          width="50"
          height="51"
          alt="INSEE"
        />
      </div>
      <div class="s-n-p-item" tabindex="0">
        <nuxt-img
          loading="lazy"
          format="webp"
          width="92"
          height="46"
          src="/images/data-gouv-fr.png"
          alt="Plateforme ouverte des données publiques françaises"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SourcesAndPartners'
}
</script>

<style lang="scss" scoped>
.sources-n-partners-block {
  margin-top: 70px;

  .title {
    display: block;
    margin: 0 0 40px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-size: var(--font-size-large-s1);
    line-height: var(--line-height-large-s1);
    text-align: center;
  }

  @include tablet-landscape {
    margin-top: 0px;
  }

  @include phone {
    display: none;
  }
}

.s-n-p-container {
  @include flex-line-center;
  gap: clamp(10px, 2.1vw, 30px);

  .s-n-p-item {
    padding: 5px;
    border-radius: 10px;
    background: var(--background-gray);
    max-width: 210px;
    width: 100%;
    min-width: 140px;
    height: 120px;
    min-height: 90px;
    @include flex-line-center;

    transition: all 0.25s ease;
    outline-offset: 6px;
    outline: 1px transparent solid;

    &:focus-visible {
      outline-offset: -1px;
      outline: 1px var(--hovered-brand) solid;
    }
  }

  @include tablet-landscape {
    gap: 30px;
    padding: 0px 10%;
    flex-wrap: wrap;
  }

  @include tablet-portrait {
    padding: 0;
  }
}
</style>
